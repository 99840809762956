import {useCallback, useEffect, useState} from "react";

import {ResponsiveDialog} from "common/components/dialog/ResponsiveDialog";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button, Dialog} from "@bolteu/kalep-react";

import {AssignmentTypeRadioGroup} from "./AssignmentTypeRadioGroup";
import {DriverComboBoxForOrderAssignment} from "./DriverComboBoxForOrderAssignment";

import AssignmentType = FleetPortalOrderService.AssignmentType;

type UpdateFormApi = FleetPortalOrderService.UpdateFormManual | FleetPortalOrderService.UpdateFormAutomatic;
interface UpdateForm {
    assignment_type: AssignmentType;
    driver_id?: number | null;
}
export interface AssignOrderData {
    orderId: number;
    assignment_type: FleetPortalOrderService.AssignmentType;
    driverId?: number;
}

const getAssignRideApiBodyFromFields = (data: AssignOrderData, fields: UpdateForm): UpdateFormApi | null => {
    if (fields.assignment_type === AssignmentType.AUTOMATIC && fields.assignment_type !== data.assignment_type) {
        return {order_id: data.orderId, assignment_type: AssignmentType.AUTOMATIC};
    }
    if (
        fields.assignment_type === AssignmentType.MANUAL &&
        (fields.assignment_type !== data.assignment_type || fields.driver_id !== data.driverId)
    ) {
        return {
            order_id: data.orderId,
            assignment_type: AssignmentType.MANUAL,
            manual_assignment_driver_id: fields.driver_id as number,
        };
    }
    return null;
};

const assignFunction = (api: Api, body: UpdateFormApi) => api.fleetPortalOrder.assignScheduledRide(body);

interface Props {
    onClose: () => void;
    onEdit: () => void;
    data: AssignOrderData;
}

export const AssignScheduledRideDialog = ({onClose, onEdit, data}: Props) => {
    const {i18n} = useI18n();
    const [fieldsToUpdate, setFieldsToUpdate] = useState<UpdateForm>({
        assignment_type: data.assignment_type,
        driver_id: data.driverId,
    });
    const [isMissingDriverId, setIsMissingDriverId] = useState(false);
    const {fetch: assignFetch, status: updateStatus} = useFetch(assignFunction);
    const isMobile = useTailwindViewport() === "sm";

    useEffect(() => {
        if (updateStatus === FetchStatus.Success) {
            onEdit();
        }
    }, [onEdit, updateStatus]);

    const onAssignClick = useCallback(async () => {
        if (!assignFetch) {
            return;
        }
        if (fieldsToUpdate.assignment_type === AssignmentType.MANUAL && !fieldsToUpdate.driver_id) {
            setIsMissingDriverId(true);
            return;
        }
        const updateBody = getAssignRideApiBodyFromFields(data, fieldsToUpdate);
        if (!updateBody) {
            onClose();
            return;
        }
        assignFetch(updateBody);
    }, [assignFetch, fieldsToUpdate, data, onClose]);

    const onAssignmentTypeChange = useCallback((newAssignmentType: AssignmentType) => {
        setFieldsToUpdate((prev) => ({...prev, assignment_type: newAssignmentType}));
    }, []);

    const onManualDriverIdChange = useCallback((newDiverId?: number) => {
        if (newDiverId) {
            setIsMissingDriverId(false);
        }
        setFieldsToUpdate((prev) => ({
            assignment_type: prev.assignment_type,
            driver_id: newDiverId ?? undefined,
        }));
    }, []);

    const isLoading = FetchStatus.Loading === updateStatus;

    const buttonClassName = isMobile ? "sm:max-w-[192px]" : "";
    const driverIdErrorStr = isMissingDriverId ? i18n("api.error.validation.required") : undefined;

    const title = data.driverId
        ? i18n("auth.app.orders.scheduled_rides.change-driver")
        : i18n("auth.app.orders.scheduled_rides.assign-driver");
    const paddingBottom = fieldsToUpdate.assignment_type === AssignmentType.MANUAL ? "" : "pb-16";

    return (
        <ResponsiveDialog title={title} isOpen onRequestClose={onClose}>
            <Dialog.Content>
                <div className={`mt-4 flex flex-col gap-4 ${paddingBottom}`}>
                    <div className="mb-4">
                        <AssignmentTypeRadioGroup
                            value={fieldsToUpdate.assignment_type}
                            onChange={onAssignmentTypeChange}
                        />
                    </div>
                    {fieldsToUpdate.assignment_type === AssignmentType.MANUAL && (
                        <DriverComboBoxForOrderAssignment
                            orderId={data.orderId}
                            selectedDriverId={fieldsToUpdate.driver_id}
                            onChange={onManualDriverIdChange}
                            validationError={driverIdErrorStr}
                        />
                    )}
                    <Alert severity="info">{i18n("auth.app.orders.scheduled_rides.scheduled-ride-info")}</Alert>
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <Button
                    onClick={onAssignClick}
                    loading={isLoading}
                    fullWidth={isMobile}
                    overrideClassName={buttonClassName}
                >
                    {i18n("common.submit")}
                </Button>
                <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={isLoading}
                    fullWidth={isMobile}
                    overrideClassName={buttonClassName}
                >
                    {i18n("common.cancel")}
                </Button>
            </Dialog.Footer>
        </ResponsiveDialog>
    );
};
