import {useI18n} from "common/hooks/useI18n";

import {Link} from "@bolteu/kalep-react";

interface Props {
    listingId: number;
}

// TODO: add link to this listing_id in VM when VM is ready
const ViewListingInVMLink = ({listingId: _listingId}: Props) => {
    const {i18n} = useI18n();

    return (
        <Link href="#TODO" target="_blank">
            {i18n("auth.app.fleet.vehicle_marketplace.listings.view.view_listing_in_vm_link_text")}
        </Link>
    );
};

export default ViewListingInVMLink;
