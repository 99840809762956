import {CommonLocalStorageService} from "@fleet/common/services/CommonLocalStorageService";

import {LocaleCode} from "./authService";

const portalPrefix = "FOP_";
const legacyPortalPrefix = "taxifyFleetOwnerPortal_";

class LocalStorageService extends CommonLocalStorageService {
    private TableColumnSettingsKey = "table_column_settings";

    constructor(keyPrefix: string) {
        super(keyPrefix);
    }

    // Arrow function is used to avoid binding issues in BrowserInfo
    public setLegacyItem = (key: string, value: string) => localStorage.setItem(`${legacyPortalPrefix}${key}`, value);

    // Arrow function is used to avoid binding issues in BrowserInfo
    public getLegacyItem = (key: string) => localStorage.getItem(`${legacyPortalPrefix}${key}`);

    public removeLegacyItem(key: string) {
        return localStorage.removeItem(`${legacyPortalPrefix}${key}`);
    }

    public removeLegacyItems(keys: string[]) {
        return keys.forEach((key) => localStorage.removeItem(`${legacyPortalPrefix}${key}`));
    }

    public getLanguageWithDefaultLocale(defaultLocale: string = "en-us") {
        return this.getLegacyItem(LocaleCode) ?? defaultLocale;
    }

    public getTableSettingsKey(tableId: string) {
        return `${this.TableColumnSettingsKey}_${tableId}`;
    }
}

export const localStorageService = new LocalStorageService(portalPrefix);
