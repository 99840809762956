import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TableColumnSettings} from "../TableWithCustomizableColumns";
import {ApiDrivenTableTransforms, UseableColumns} from "./types";
import {hasRenderers, isColumnCustomizable, isColumnVisible, isStateColumn} from "./util";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ListColumnType = FleetOwnerPortalService.ListColumnType;
import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;
import Accessibility = FleetOwnerPortalService.Accessibility;
import StatusColumn = FleetOwnerPortalService.StatusColumn;

export function useApiDrivenTableColumns(
    tableId: string,
    columns: ApiColumn[],
    transforms?: ApiDrivenTableTransforms,
): UseableColumns {
    const customizableColumns = columns.filter((col) => hasRenderers(col, transforms)).filter(isColumnCustomizable);

    const [value] = useLocalStorage<TableColumnSettings>(localStorageService.getTableSettingsKey(tableId), {});
    const userDefinedVisibleColumns = columns
        .filter((col) => hasRenderers(col, transforms) && !isStateColumn(col) && isColumnVisible(col))
        .filter((column) => {
            return value[column.key] ?? column.accessibility === Accessibility.VISIBLE;
        });

    const referenceColumns = columns.filter(
        (column) => column.type === ListColumnType.REFERENCE_V2,
    ) as ReferenceColumn[];
    const subColumns = columns
        .filter((col) => hasRenderers(col, transforms) && !isStateColumn(col))
        .filter((column) => column.relation_key);
    const stateColumns = columns.filter(
        (column) => column.type === FleetOwnerPortalService.ListColumnType.STATE,
    ) as FleetOwnerPortalService.StateColumn[];

    // Assumption is that there's only one status column for now
    const statusColumn = columns.find((c) => c.type === ListColumnType.STATUS) as StatusColumn | undefined;

    return {
        subColumns,
        stateColumns,
        referenceColumns,
        customizableColumns,
        userDefinedVisibleColumns,
        statusColumn,
    };
}
