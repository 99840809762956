import {useIntl} from "react-intl";

import {formatNumber} from "common/util/FormatUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {AlignHorizontal} from "../types";

import NumberType = FleetOwnerPortalService.NumberType;

interface Props {
    value: number;
    unit?: string;
    numberType: NumberType;
    isSubCell?: boolean;
    alignHorizontal?: AlignHorizontal;
}

export const NumberCell = ({unit, alignHorizontal, numberType, value, isSubCell}: Props): React.ReactElement => {
    const intl = useIntl();
    const options = numberType === NumberType.INTEGER ? {minFractionDigits: 0, maxFractionDigits: 0} : {};
    const formattedValue = formatNumber(intl, value, options);

    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    const horizontalAlign = alignHorizontal === AlignHorizontal.RIGHT ? "justify-end" : "justify-start";

    if (!value) {
        return (
            <Typography variant="body-tabular-s-regular" color="tertiary" fontSize={fontSize}>
                {formattedValue}
            </Typography>
        );
    }

    return (
        <div className={`flex w-full items-baseline gap-x-1 ${horizontalAlign}`}>
            <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                {formattedValue}
            </Typography>{" "}
            <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                {unit}
            </Typography>
        </div>
    );
};
