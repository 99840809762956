import {Link} from "react-router-dom";

import {Dash} from "common/constants";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {CellProps} from "../types";
import {StateDisplayIndicator, StateDisplayText} from "./StateCell";

import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;
import ReferenceEntity = FleetOwnerPortalService.ReferenceEntity;
import ReferenceType = FleetOwnerPortalService.ReferenceType;
import StateColumn = FleetOwnerPortalService.StateColumn;

interface Props extends CellProps {
    isSecondary?: boolean;
    displayAsLink?: boolean;
    stateColumn?: StateColumn;
}

export const ReferenceCell = (cellProps: Props) => {
    const {getDriverDetailsPath, getVehicleDetailsPath} = useAbsolutePath();

    const getLinkTo = () => {
        const {reference_type, cells} = (cellProps.column as ReferenceColumn) || {};
        const entityId = cells?.[cellProps.valueAtIdx]?.id;

        if (!entityId || Number.isNaN(Number(entityId))) {
            return undefined;
        }

        switch (reference_type) {
            case ReferenceType.DRIVER:
                return getDriverDetailsPath(Number(entityId));
            case ReferenceType.VEHICLE:
                return getVehicleDetailsPath(Number(entityId));
            default:
                return undefined;
        }
    };

    const linkTo = getLinkTo();
    const cellData = cellProps.column.cells?.[cellProps.valueAtIdx];
    const name = (cellData as ReferenceEntity)?.name || Dash.EN;

    const primaryCellValue = (
        <Typography
            fontSize="text-sm"
            variant="body-tabular-m-regular"
            color={cellProps.isSecondary || name === Dash.EN ? "secondary" : undefined}
        >
            {name}
        </Typography>
    );

    return (
        <div className="flex items-center gap-3">
            {cellProps.stateColumn?.cells[cellProps.valueAtIdx] && (
                <StateDisplayIndicator entityState={cellProps.stateColumn.cells[cellProps.valueAtIdx]} />
            )}
            <div className="flex flex-col">
                {cellProps.displayAsLink && linkTo ? (
                    <Link className="hover:underline" to={linkTo}>
                        {primaryCellValue}
                    </Link>
                ) : (
                    primaryCellValue
                )}
                {cellProps.stateColumn && (
                    <StateDisplayText stateColumn={cellProps.stateColumn} valueAtIdx={cellProps.valueAtIdx} />
                )}
            </div>
        </div>
    );
};
