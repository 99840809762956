import {useContext, useEffect, useState} from "react";

import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";

const FIVE_MINUTES_IN_MILLIS = 5 * 60 * 1000;

export const useBrowserGeoLocation = () => {
    const {bugsnag} = useContext(ThirdPartyContextProvider);
    const [geoLocation, setGeoLocation] = useState<GeolocationPosition | null>(null);

    useEffect(() => {
        let watchPositionId: number | undefined;
        if (navigator.geolocation) {
            const successCallback = (position: GeolocationPosition) => {
                setGeoLocation(position);
            };
            const errorCallback = (error: GeolocationPositionError) => {
                let errorMsg: string;
                if (error.code === error.PERMISSION_DENIED) {
                    errorMsg = "User denied the request for Geolocation";
                } else if (error.code === error.POSITION_UNAVAILABLE) {
                    errorMsg = "Location information is unavailable";
                } else if (error.code === error.TIMEOUT) {
                    errorMsg = "The request to get user location timed out";
                } else {
                    errorMsg = "An unknown error occurred";
                }
                bugsnag.bugsnagNotify(new Error(`Geo location error, ${errorMsg}`, {cause: error}));
            };
            watchPositionId = navigator.geolocation.watchPosition(successCallback, errorCallback, {
                maximumAge: FIVE_MINUTES_IN_MILLIS,
            });
        }
        return () => {
            if (navigator.geolocation && watchPositionId) {
                navigator.geolocation.clearWatch(watchPositionId);
            }
        };
    }, [bugsnag]);

    return geoLocation;
};
