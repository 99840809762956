import {useCallback, useMemo} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod, Period} from "common/services/period";
import {getIsoWeek, getYearMonth} from "common/util/FormatUtil";
import {addMonths, addWeeks, subMonths, subWeeks} from "date-fns";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {SelectOption} from "@bolteu/kalep-react";

export enum SelectPeriodOption {
    OnGoingDay = "ongoing_day",
    Previous7Days = "previous_7_days",
    LastWeek = "last_week",
    LastMonth = "last_month",
    ThisWeek = "this_week",
    NextWeek = "next_week",
    ThisMonth = "this_month",
    NextMonth = "next_month",
}

export const selectPeriodOptionsUntilNow = [
    SelectPeriodOption.OnGoingDay,
    SelectPeriodOption.Previous7Days,
    SelectPeriodOption.LastWeek,
    SelectPeriodOption.LastMonth,
];

interface PeriodSelection {
    value: string;
    apiPeriod: ApiPeriod;
    isPeriod: (period: ApiPeriod) => boolean;
    title: string;
}

export function usePeriodSelectorOptions(selectPeriodOptions: SelectPeriodOption[]) {
    const {i18n} = useI18n();

    const selectPeriodOptionToPeriodSelection = useCallback(
        (selectPeriod: SelectPeriodOption): PeriodSelection | null => {
            switch (selectPeriod) {
                case SelectPeriodOption.OnGoingDay:
                    return {
                        value: SelectPeriodOption.OnGoingDay,
                        apiPeriod: {period: Period.ONGOING_DAY},
                        isPeriod: (period: ApiPeriod) => period.period === Period.ONGOING_DAY,
                        title: i18n("auth.app.fleet.reports.current_day"),
                    };
                case SelectPeriodOption.Previous7Days:
                    return {
                        value: SelectPeriodOption.Previous7Days,
                        apiPeriod: {period: Period.PREVIOUS_7_DAYS},
                        isPeriod: (period: ApiPeriod) => period.period === Period.PREVIOUS_7_DAYS,
                        title: i18n("auth.app.fleet.reports.last_7d"),
                    };
                case SelectPeriodOption.LastWeek: {
                    const lastWeek = getIsoWeek(subWeeks(new Date(), 1));
                    return {
                        value: SelectPeriodOption.LastWeek,
                        apiPeriod: {period: Period.WEEK, week: lastWeek},
                        isPeriod: (period: ApiPeriod) => period.period === Period.WEEK && period.week === lastWeek,
                        title: i18n("auth.app.fleet.reports.last_week"),
                    };
                }
                case SelectPeriodOption.LastMonth: {
                    const lastMonth = getYearMonth(subMonths(new Date(), 1));
                    return {
                        value: SelectPeriodOption.LastMonth,
                        apiPeriod: {period: Period.MONTH, month: lastMonth},
                        isPeriod: (period: ApiPeriod) => period.period === Period.MONTH && period.month === lastMonth,
                        title: i18n("auth.app.fleet.reports.last_month"),
                    };
                }
                case SelectPeriodOption.ThisWeek: {
                    const thisWeek = getIsoWeek(new Date());
                    return {
                        value: SelectPeriodOption.ThisWeek,
                        apiPeriod: {period: Period.WEEK, week: thisWeek},
                        isPeriod: (period: ApiPeriod) => period.period === Period.WEEK && period.week === thisWeek,
                        title: i18n("auth.app.period-selector.this_week"),
                    };
                }
                case SelectPeriodOption.NextWeek: {
                    const nextWeek = getIsoWeek(addWeeks(new Date(), 1));
                    return {
                        value: SelectPeriodOption.NextWeek,
                        apiPeriod: {period: Period.WEEK, week: nextWeek},
                        isPeriod: (period: ApiPeriod) => period.period === Period.WEEK && period.week === nextWeek,
                        title: i18n("auth.app.period-selector.next_week"),
                    };
                }
                case SelectPeriodOption.ThisMonth: {
                    const thisMonth = getYearMonth(new Date());
                    return {
                        value: SelectPeriodOption.ThisMonth,
                        apiPeriod: {period: Period.MONTH, month: thisMonth},
                        isPeriod: (period: ApiPeriod) => period.period === Period.MONTH && period.month === thisMonth,
                        title: i18n("auth.app.period-selector.this_month"),
                    };
                }
                case SelectPeriodOption.NextMonth: {
                    const nextMonth = getYearMonth(addMonths(new Date(), 1));
                    return {
                        value: SelectPeriodOption.NextMonth,
                        apiPeriod: {period: Period.MONTH, month: nextMonth},
                        isPeriod: (period: ApiPeriod) => period.period === Period.MONTH && period.month === nextMonth,
                        title: i18n("auth.app.period-selector.next_month"),
                    };
                }
                default:
                    UnreachableCode.never(selectPeriod);
                    return null;
            }
        },
        [i18n],
    );

    const selections: PeriodSelection[] = useMemo(() => {
        const list: PeriodSelection[] = selectPeriodOptions
            .map(selectPeriodOptionToPeriodSelection)
            .filter((selection): selection is PeriodSelection => selection !== null);
        return list;
    }, [selectPeriodOptionToPeriodSelection, selectPeriodOptions]);

    const customPeriodSelectOption = useMemo(
        () => ({
            value: "custom",
            title: i18n("auth.app.fleet.reports.custom"),
            disabled: false,
        }),
        [i18n],
    );

    const selectOptions = useMemo<SelectOption[]>(() => {
        const options = selections.map(({value, title}) => ({value, title, disabled: false}));
        options.push(customPeriodSelectOption);
        return options;
    }, [selections, customPeriodSelectOption]);

    const selectionToPeriodMap = useMemo<Map<string, ApiPeriod>>(() => {
        const map = new Map<string, ApiPeriod>();
        selections.forEach(({value, apiPeriod}) => map.set(value, apiPeriod));
        return map;
    }, [selections]);

    const getSelectOption = (period: ApiPeriod): SelectOption => {
        const periodSelection = selections.find((selection) => selection.isPeriod(period));
        const selectOption = periodSelection && selectOptions.find((opt) => opt.value === periodSelection.value);
        return selectOption || customPeriodSelectOption;
    };
    return {selectOptions, selectionToPeriodMap, getSelectOption};
}
