import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import Pagination from "common/components/Pagination";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getCurrentDate} from "common/util/dateUtil";
import {getIsoDate} from "common/util/FormatUtil";
import {addDays} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";

import {AssignOrderData, AssignScheduledRideDialog} from "./components/AssignScheduledRideDialog";
import {CancelScheduledRideDialog} from "./components/CancelScheduledRideDialog";
import {CreateOrderDrawer} from "./components/create-drawer";
import {ScheduledRidesTable} from "./components/ScheduledRidesTable";
import {TableFiltersAndActions} from "./components/TableFiltersAndActions";
import {DATE_RANGE_LIMIT_DAYS, SCHEDULED_RIDES_TABLE_MAX_LIMIT} from "./constants";
import {CreateDrawerType} from "./types";

const getScheduledRidesFunction = (api: Api, body: FleetPortalOrderService.GetScheduledRidesRequest) =>
    api.fleetPortalOrder.getScheduledRides(body);

const ScheduledRides = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const [pageNum, setPageNum] = useState(0);
    const [driverSearchValue, setDriverSearchValue] = useState<number[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({
        period: Period.CUSTOM,
        startDate: getCurrentDate(),
        endDate: addDays(getCurrentDate(), DATE_RANGE_LIMIT_DAYS - 1),
    });
    const [scheduledRideIdToDelete, setScheduledRideIdToDelete] = useState<number | null>(null);
    const [assignOrderData, setAssignOrderData] = useState<AssignOrderData | null>(null);
    const [createOrderDrawerType, setCreateOrderDrawerType] = useState<CreateDrawerType | null>(null);
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {getLiveMapPath} = useAbsolutePath();

    const {
        data: scheduledRides,
        fetch: scheduledRidesFetch,
        status: scheduledRidesStatus,
    } = useFetch(getScheduledRidesFunction);

    const getRequestDates = useCallback((period: ApiPeriod) => {
        return period.period === Period.CUSTOM
            ? {start: period.startDate, end: period.endDate}
            : apiPeriodToDates(period);
    }, []);

    const getScheduledRides = useCallback(
        async (newPageNum: number) => {
            if (scheduledRidesFetch) {
                const {start, end} = getRequestDates(selectedPeriod);
                const offset = newPageNum * SCHEDULED_RIDES_TABLE_MAX_LIMIT;
                scheduledRidesFetch({
                    offset,
                    limit: SCHEDULED_RIDES_TABLE_MAX_LIMIT,
                    start: getIsoDate(start),
                    end: getIsoDate(end),
                    ...(driverSearchValue.length && {driver_ids: driverSearchValue}),
                });
            }
        },
        [driverSearchValue, getRequestDates, scheduledRidesFetch, selectedPeriod],
    );

    useEffect(() => {
        if (fleet) {
            setPageNum(0);
            getScheduledRides(0);
        }
    }, [fleet, getScheduledRides]);

    const onCloseCancelDialog = useCallback(() => {
        setScheduledRideIdToDelete(null);
    }, []);

    const onCancel = useCallback(() => {
        setScheduledRideIdToDelete(null);
        getScheduledRides(pageNum);
    }, [getScheduledRides, pageNum]);

    const onCloseEditDialog = useCallback(() => {
        setAssignOrderData(null);
    }, []);

    const onCloseCreateDialog = useCallback(() => {
        setCreateOrderDrawerType(null);
    }, []);

    const onEdit = useCallback(() => {
        setAssignOrderData(null);
        getScheduledRides(pageNum);
    }, [getScheduledRides, pageNum]);

    const navigateToLiveMap = useCallback(() => {
        navigate(getLiveMapPath());
    }, [getLiveMapPath, navigate]);

    const onCreateScheduledOrder = useCallback(() => {
        getScheduledRides(pageNum);
        snackbar.add(
            {
                description: i18n("auth.app.orders.scheduled_rides.scheduled-order-created"),
                dismissible: false,
            },
            {timeout: 4000},
        );
    }, [getScheduledRides, i18n, pageNum, snackbar]);

    const onCreateInstantOrder = useCallback(() => {
        snackbar.add(
            {
                title: i18n("auth.app.orders.scheduled_rides.instant-order-created"),
                description: i18n("auth.app.orders.scheduled_rides.instant-order-created-description"),
                dismissible: false,
                actions: [
                    {
                        label: i18n("auth.app.orders.scheduled_rides.instant-order-created-action"),
                        onClick: navigateToLiveMap,
                    },
                ],
            },
            {timeout: 6000},
        );
    }, [i18n, navigateToLiveMap, snackbar]);

    const onCreate = useCallback(
        (type: CreateDrawerType) => {
            setCreateOrderDrawerType(null);
            switch (type) {
                case CreateDrawerType.SCHEDULE:
                    onCreateScheduledOrder();
                    break;
                case CreateDrawerType.INSTANT:
                    onCreateInstantOrder();
                    break;
                default:
                    UnreachableCode.never(type);
            }
        },
        [onCreateInstantOrder, onCreateScheduledOrder],
    );

    const onPageChange = useCallback(
        async (page: number) => {
            if (page === pageNum) {
                return;
            }
            getScheduledRides(page);
            setPageNum(page);
        },
        [getScheduledRides, pageNum],
    );

    const onCreateOrderClick = useCallback(
        (type: CreateDrawerType) => () => {
            setCreateOrderDrawerType(type);
        },
        [],
    );

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(scheduledRidesStatus);

    if (scheduledRidesStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    const AssignOrderDialog = assignOrderData ? (
        <AssignScheduledRideDialog onClose={onCloseEditDialog} onEdit={onEdit} data={assignOrderData} />
    ) : null;

    const CreateOrderDrawerWrapper = createOrderDrawerType ? (
        <CreateOrderDrawer type={createOrderDrawerType} onClose={onCloseCreateDialog} onCreate={onCreate} />
    ) : null;

    return (
        <div className="mx-auto mb-14 flex h-full w-full flex-col gap-6">
            <CancelScheduledRideDialog
                orderdId={scheduledRideIdToDelete}
                show={!!scheduledRideIdToDelete}
                onClose={onCloseCancelDialog}
                onCancel={onCancel}
            />
            {AssignOrderDialog}
            {CreateOrderDrawerWrapper}
            <TableFiltersAndActions
                selectedPeriod={selectedPeriod}
                onSearchChange={setDriverSearchValue}
                onPeriodChange={setSelectedPeriod}
                onCreateOrderClick={onCreateOrderClick}
            />
            <ScheduledRidesTable
                scheduledRidesResponse={
                    scheduledRides ?? {total_rides: 0, columns: [], order_ids: [], category_ids: []}
                }
                isLoading={isLoading}
                editScheduledRide={setAssignOrderData}
                deleteScheduledRide={setScheduledRideIdToDelete}
            />
            <Pagination
                currentPage={pageNum}
                pageSize={SCHEDULED_RIDES_TABLE_MAX_LIMIT}
                totalCount={scheduledRides?.total_rides || 0}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </div>
    );
};

export default ScheduledRides;
