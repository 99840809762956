import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Radio, RadioGroup, Typography} from "@bolteu/kalep-react";

import {getPaymentMethodLabel} from "../../util";

interface Props {
    selectedPaymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod;
    onChange: (paymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod) => void;
}

export const PaymentMethods = ({selectedPaymentMethod, onChange}: Props) => {
    const {i18n} = useI18n();
    const availablePaymentMethods = useContext(ScheduledRidesContextProvider).getAvailablePaymentMethods();

    const onPaymentMethodChange = useCallback(
        (event) => {
            onChange(event.target.value as FleetPortalOrderService.CreateOrderPaymentMethod);
        },
        [onChange],
    );

    if (availablePaymentMethods.length <= 1) {
        return null;
    }

    return (
        <div className="mb-4">
            <Typography fontWeight="semibold" fontSize="text-sm" paddingBottom={4}>
                {i18n("auth.app.orders.scheduled_rides.payment-type")}
            </Typography>
            <RadioGroup name="payment_method" value={selectedPaymentMethod} onChange={onPaymentMethodChange}>
                <div className="flex flex-col gap-6">
                    {availablePaymentMethods.map((paymentMethod) => (
                        <Radio
                            key={paymentMethod}
                            label={getPaymentMethodLabel(paymentMethod, i18n)}
                            value={paymentMethod}
                        />
                    ))}
                </div>
            </RadioGroup>
        </div>
    );
};
