import {useContext} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {formatNumber} from "common/util/FormatUtil";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {PaymentOutlined, Route} from "@bolteu/kalep-react-icons";

import {getPaymentMethodLabel} from "../../../util";

interface Props {
    category: FleetPortalOrderService.Category;
}

export const RideInfo = ({category}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const paymentMethods = useContext(ScheduledRidesContextProvider).getAvailablePaymentMethods();

    const distanceAndTime =
        category.ride_distance && category.ride_time_min ? (
            <>
                <span className="flex items-center gap-1">
                    <Route size="xs" /> {formatNumber(intl, category.ride_distance, {maxFractionDigits: 1})}{" "}
                    <span>{category.ride_distance_unit}</span>
                </span>
                <span>•</span>
                <span>
                    {category.ride_time_min} {i18n("common.minute_abbreviation")}
                </span>
            </>
        ) : null;
    const paymentMethodBorder = distanceAndTime ? (
        <div className="border-content-tertiary absolute bottom-1/4 inline-block h-1/2 border-l" />
    ) : null;
    const paymentMethodPadding = distanceAndTime ? "pl-[6px]" : "";
    const paymentMethod =
        paymentMethods.length === 1 ? (
            <div className="relative">
                {paymentMethodBorder}
                <span className={`flex items-center justify-center gap-1 ${paymentMethodPadding}`}>
                    <PaymentOutlined size="xs" />
                    {getPaymentMethodLabel(paymentMethods[0], i18n)}
                </span>
            </div>
        ) : null;
    if (!paymentMethod && !distanceAndTime) {
        return null;
    }
    return (
        <div className="text-secondary mb-2 flex items-center justify-start gap-1">
            {distanceAndTime}
            {paymentMethod}
        </div>
    );
};
