import {useCallback, useContext} from "react";
import {useIntl} from "react-intl";

import {useBrowserGeoLocation} from "common/geo/hooks/useBrowserGeoLocation";
import {useI18n} from "common/hooks/useI18n";
import {getCurrentDate} from "common/util/dateUtil";
import {getDateFormat} from "common/util/FormatUtil";
import {addDays} from "date-fns";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {DatePicker} from "@bolteu/kalep-react";

import {Address, CreateDrawerType, Step1Data} from "../../../types";
import {Stop} from "../Stop";

interface Props {
    data: Step1Data;
    onChange: (data: Partial<Step1Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
}

export const RouteDetails = ({drawerType, data, onChange, validationErrors}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const schedulingLimits = useContext(ScheduledRidesContextProvider).getSchedulingLimits();
    const geoLocation = useBrowserGeoLocation();

    const onDateTimeChange = useCallback(
        (newDate: Date | Date[] | null) => {
            if (newDate && !Array.isArray(newDate)) {
                onChange({scheduled_for: newDate});
            }
        },
        [onChange],
    );

    const onPickupChange = useCallback(
        (newAddress: Address) => {
            onChange({pickup: newAddress});
        },
        [onChange],
    );

    const onDropoffChange = useCallback(
        (newAddress: Address) => {
            const indexToReplace = data.dropoffs.findIndex((address) => address.key === newAddress?.key);
            const addresses = [...data.dropoffs];
            addresses.splice(indexToReplace, 1, newAddress);
            onChange({dropoffs: addresses});
        },
        [data.dropoffs, onChange],
    );

    const scheduledAtError = validationErrors?.find((error) => error.property === "scheduled_for")?.error;
    const pickupError = validationErrors?.find((error) => error.property === "pickup")?.error;
    const generalDropoffError = validationErrors?.find(
        (error) => error.property === "dropoffs" && !error.children,
    )?.error;
    const isScheduledOrder = drawerType === CreateDrawerType.SCHEDULE;

    return (
        <div>
            {isScheduledOrder && (
                <DatePicker
                    label={i18n("common.date")}
                    value={data.scheduled_for}
                    onChange={onDateTimeChange}
                    hasTimeSelect
                    format={`${getDateFormat(intl)} HH:mm`}
                    minDate={getCurrentDate()}
                    maxDate={addDays(getCurrentDate(), schedulingLimits.maximum_date_in_future_days)}
                    locale={intl.locale}
                    fullWidth
                    isError={Boolean(scheduledAtError)}
                    helperText={scheduledAtError}
                />
            )}
            <div className="mt-8 flex flex-col">
                <Stop
                    isPickup
                    address={data.pickup}
                    onChange={onPickupChange}
                    validationError={pickupError}
                    geoLocation={geoLocation}
                />
                {data.dropoffs.map((address, index, addresses) => {
                    const isLastDropoff = index === addresses.length - 1;
                    const validationError = validationErrors?.find((error) => error.property === "dropoffs");
                    let stopError = validationError?.children?.find((error) => error.property === address.key)?.error;
                    if (!stopError && isLastDropoff && generalDropoffError) {
                        stopError = generalDropoffError;
                    }
                    return (
                        <Stop
                            key={address.key}
                            address={address}
                            isLastAddressField={isLastDropoff}
                            onChange={onDropoffChange}
                            validationError={stopError}
                            geoLocation={geoLocation}
                        />
                    );
                })}
            </div>
        </div>
    );
};
