import {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {getEntityPortalStatusData} from "common/components/toggleDeactivationButton/getEntityPortalStatusData";
import {useReactRouterLinkForBreadcrumb} from "common/hooks/useBreadcrumbRenderItem";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb} from "@bolteu/kalep-react";

import VehicleDetailsBody from "./components/VehicleDetailsBody";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

const getCarFetch = (api: Api, query: FleetOwnerPortalService.GetCarRequest) => api.fleetOwnerPortal.getCar(query);

const VehicleDetailsPage = () => {
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const {id: vehicleIdStr} = useParams();
    const breadcrumbs = useBreadcrumbs(fleetId);

    const [vehicle, setVehicle] = useState<FleetOwnerPortalService.GetCarResponse | null>(null);

    const {status, data, fetch} = useFetch(getCarFetch);

    const toggleVehicleDeactivationStatus = useCallback(
        (newPortalStatus: EntityPortalStatus) => {
            if (vehicle) {
                setVehicle({
                    ...vehicle,
                    ...getEntityPortalStatusData(newPortalStatus),
                });
            }
        },
        [vehicle],
    );

    useEffect(() => {
        if (status === FetchStatus.Success) {
            setVehicle(data);
        }
    }, [status, data]);

    useEffect(() => {
        if (!fetch || !vehicleIdStr || !fleetId) {
            return;
        }

        fetch({id: Number(vehicleIdStr)});
    }, [fetch, vehicleIdStr, fleetId]);

    useEffect(() => {
        if (status === FetchStatus.Success && vehicle) {
            const vehicleTitle = `${vehicle.model} (${vehicle.year})`;
            document.title = getDocumentTitle(vehicleTitle);
        }
    }, [vehicle, status]);

    let Body: React.ReactNode = null;
    switch (status) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Body = <LoadingSpinner show showLoadingText parentClassName="mt-9" />;
            break;
        case FetchStatus.Error:
            Body = <ErrorView type={ErrorViewType.NotFound} />;
            break;
        case FetchStatus.Success:
            if (vehicle) {
                Body = (
                    <VehicleDetailsBody
                        vehicle={vehicle}
                        toggleVehicleDeactivationStatus={toggleVehicleDeactivationStatus}
                    />
                );
            }
            break;
        default:
            UnreachableCode.never(status);
    }

    return (
        <div className="container mx-auto flex w-full flex-col px-6 py-8">
            <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
            {Body}
        </div>
    );
};

export default VehicleDetailsPage;
