import {FC, useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {Button, IconButton, Menu, MenuItem} from "@bolteu/kalep-react";
import {MoreIos} from "@bolteu/kalep-react-icons";

import useCallForBranding from "../hooks/useCallForBranding";

interface PageHeaderProps {
    openAddVehiclePage: () => void;
    tabSelectorComponent: FC;
}

const VehiclesPageHeader: FC<PageHeaderProps> = ({openAddVehiclePage, tabSelectorComponent}) => {
    const {getCarRentalsPath, getVehicleListingCreatePath} = useAbsolutePath();
    const navigate = useNavigate();

    const {i18n} = useI18n();

    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);

    const isCarRentalPaymentsEnabled = fleet?.targeting.car_rental_payments_enabled;
    const isVehicleMarketplaceEnabled = features?.vehicle_marketplace;

    const goToCarRentalPayments = useCallback(() => navigate(getCarRentalsPath()), [navigate, getCarRentalsPath]);
    const goToVehicleMarketplace = useCallback(
        () => navigate(getVehicleListingCreatePath()),
        [navigate, getVehicleListingCreatePath],
    );

    const callForBrandingComponent = useCallForBranding();

    const TabSelector = tabSelectorComponent;

    const menus: Array<{label: string; onClick: () => void}> = [];
    if (isCarRentalPaymentsEnabled) {
        menus.push({onClick: goToCarRentalPayments, label: i18n("auth.app.fleet.vehicles.charge_rental_payments")});
    }

    if (isVehicleMarketplaceEnabled) {
        menus.push({
            onClick: goToVehicleMarketplace,
            label: i18n("auth.app.fleet.vehicle_marketplace.listings.add.menu_title"),
        });
    }

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-row items-baseline justify-between">
                <div className="flex flex-col gap-2">
                    <ResponsiveHeader
                        type={ResponsiveHeaderType.MainPage}
                        text={i18n("auth.app.fleet.vehicles.title")}
                    />
                    {callForBrandingComponent}
                </div>
                <div className="flex gap-4">
                    <Button onClick={openAddVehiclePage}>{i18n("auth.app.fleet.dashboard.add-vehicle")}</Button>
                    {!!menus.length && (
                        <Menu menuButton={<IconButton icon={<MoreIos />} aria-label="Add Vehicle Options" />}>
                            {menus.map((menu) => (
                                <MenuItem key={menu.label} label={menu.label} onClick={menu.onClick} />
                            ))}
                        </Menu>
                    )}
                </div>
            </div>
            <TabSelector />
        </div>
    );
};

export default VehiclesPageHeader;
