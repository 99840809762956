import {useContext} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ListingContextProvider} from "./ListingInfoProvider";
import BodyType = VehicleMarketplaceService.CarModelBodyType;

const WizardSummary = () => {
    const {listing} = useContext(ListingContextProvider);

    const title = listing ? `${listing.data.vehicle_manufacturer} ${listing.data.vehicle_model_name}` : "";

    return (
        <div>
            <div>
                <img
                    src={getVehiclePictureByType(listing?.data.model_body_type ?? BodyType.SEDAN)}
                    alt="Vehicle"
                    className="h-48 object-cover"
                />
            </div>
            <Header size={HeaderSize.Small} text={title} />
        </div>
    );
};

export default WizardSummary;
