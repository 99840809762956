import Pagination from "common/components/Pagination";
import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import TableSearchField from "@fleet/common/components/table/TableSearchField";
import {I18nFn} from "@fleet/common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import RentingOutAccordionTable from "./RentingOutAccordionTable";
import RentingOutFilter from "./RentingOutFilter";
import RentingOutFullTable from "./RentingOutFullTable";

interface RentingOutTableProps {
    searchCompanyListingsData: VehicleMarketplaceService.SearchCompanyListingsResponse | null;
    onSearchChange: (searchQuery: string) => void;
    currentPage: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    isLoading: boolean;
    totalCount: number;
    onFiltersApplied: (statuses: VehicleMarketplaceService.ListingStatus[] | undefined) => void;
}

export default function RentingOutTable({
    searchCompanyListingsData,
    onSearchChange,
    currentPage,
    totalCount,
    pageSize,
    onPageChange,
    isLoading,
    onFiltersApplied,
}: RentingOutTableProps) {
    const {i18n} = useI18n();

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return (
        <div className="w-full">
            <div className="flex gap-4 sm:max-w-full md:max-w-md">
                <TableSearchField
                    onChange={onSearchChange}
                    placeholder={i18n("auth.app.fleet.vehicle_marketplace.listings.table.search_by_model")}
                />
                <RentingOutFilter onApply={onFiltersApplied} />
            </div>
            <div className="mt-6 w-full">
                {isMobileView ? (
                    <RentingOutAccordionTable items={searchCompanyListingsData ?? {rows: [], count: 0}} />
                ) : (
                    <RentingOutFullTable
                        items={searchCompanyListingsData ?? {rows: [], count: 0}}
                        isLoading={isLoading}
                    />
                )}
            </div>
            <Pagination
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={totalCount}
                onPageChange={onPageChange}
                disabled={isLoading}
                showText
            />
        </div>
    );
}

export function offerTypeToReadable(
    status: VehicleMarketplaceService.OfferType | null,
    i18n: I18nFn<TranslationKeys>,
): string {
    switch (status) {
        case VehicleMarketplaceService.OfferType.RENT:
            return i18n("auth.app.fleet.vehicle_marketplace.listings.table.rent");
        case VehicleMarketplaceService.OfferType.RENT_TO_BUY:
            return i18n("auth.app.fleet.vehicle_marketplace.listings.table.rent_to_buy");
        default:
            return "-";
    }
}
