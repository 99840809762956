import FilledCircle from "assets/icons/filled_circle.svg?react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {CellRenderer} from "./cell-renderers";
import {ReferenceCell} from "./cell-renderers/ReferenceCell";
import {StateDisplayText} from "./cell-renderers/StateCell";
import {StatusChip} from "./cell-renderers/StatusCell";
import {isDateColumn, isReferenceColumn} from "./util";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import EntityState = FleetOwnerPortalService.EntityState;
import StateColumn = FleetOwnerPortalService.StateColumn;
import StatusColumn = FleetOwnerPortalService.StatusColumn;

interface MobileAccordionTitleProps {
    rowIdx: number;
    userDefinedVisibleColumns: FleetOwnerPortalService.ApiColumn[];
    stateColumns: StateColumn[] | undefined;
    statusColumn: StatusColumn | undefined;
}

function isStateAlertOrInactive(rowIdx: number, stateColumn?: StateColumn) {
    return stateColumn && [EntityState.ALERT, EntityState.INACTIVE].includes(stateColumn.cells[rowIdx]);
}

function shouldRenderVisibleColumns(referenceColumns: ApiColumn[], visibleColumns: ApiColumn[]) {
    return referenceColumns.length <= 1 && visibleColumns[0];
}

export default function MobileAccordionTitle({
    rowIdx,
    userDefinedVisibleColumns,
    stateColumns,
    statusColumn,
}: MobileAccordionTitleProps) {
    const visibleColumns = userDefinedVisibleColumns.filter((column) => !isReferenceColumn(column));
    const referenceColumns = userDefinedVisibleColumns.filter(
        (column) => isDateColumn(column) || isReferenceColumn(column),
    );

    return (
        <div className="flex justify-between">
            <div className="flex-col items-center gap-3">
                {referenceColumns.map((column, idx) => {
                    const matchingStateColumn = stateColumns?.find(
                        (stateColumn) => stateColumn.relation_key === column.key,
                    );

                    return (
                        <div key={column.key} className="flex items-center gap-x-1">
                            {isReferenceColumn(column) ? (
                                <>
                                    {isStateAlertOrInactive(rowIdx, matchingStateColumn) && (
                                        <>
                                            <StateDisplayText stateColumn={matchingStateColumn} valueAtIdx={rowIdx} />
                                            <FilledCircle
                                                width={4}
                                                height={4}
                                                className="self-center fill-neutral-700"
                                            />
                                        </>
                                    )}
                                    <ReferenceCell column={column} valueAtIdx={rowIdx} isSecondary={idx > 0} />
                                </>
                            ) : (
                                <CellRenderer valueAtIdx={rowIdx} column={column} />
                            )}
                        </div>
                    );
                })}
                {shouldRenderVisibleColumns(referenceColumns, visibleColumns) && (
                    <Typography variant="body-tabular-s-regular" color="secondary">
                        <span className="mr-1">{`${visibleColumns[0].title}:`}</span>
                        <span className="inline-flex items-baseline">
                            <CellRenderer column={visibleColumns[0]} valueAtIdx={rowIdx} isSubCell />
                        </span>
                    </Typography>
                )}
            </div>
            {statusColumn && <StatusChip value={statusColumn.cells[rowIdx]} valueMap={statusColumn.value_map} />}
        </div>
    );
}
