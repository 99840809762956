import {AddressDestination, AddressPickup, PinDestination} from "@bolteu/kalep-react-icons";

import {Address, AddressType} from "../../types";
import {AddressSuggestion} from "./AddressSuggestion";

interface Props {
    isPickup?: boolean;
    address: Address;
    isLastAddressField?: boolean;
    onChange: (address: Address) => void;
    validationError?: string;
    geoLocation: GeolocationPosition | null;
}

export const Stop = ({isPickup, address, isLastAddressField, onChange, validationError, geoLocation}: Props) => {
    let Icon = AddressPickup;
    let addressType = AddressType.Pickup;
    if (!isPickup) {
        Icon = isLastAddressField ? PinDestination : AddressDestination;
        addressType = isLastAddressField ? AddressType.Dropoff : AddressType.Stop;
    }

    return (
        <div className="flex gap-3">
            <div className="pt-[40px]">
                <Icon className="mb-[5px]" />
                {!isLastAddressField && (
                    <div className="relative left-[calc(50%-1px)] h-[calc(100%+6px)] border-l-[2px] border-purple-200" />
                )}
            </div>
            <div className={`w-full ${!isLastAddressField ? "pb-3" : ""}`}>
                <AddressSuggestion
                    type={addressType}
                    address={address}
                    onChange={onChange}
                    validationError={validationError}
                    geoLocation={geoLocation}
                />
            </div>
        </div>
    );
};
