import {ChangeEvent, ForwardedRef, forwardRef, useCallback, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {EventName} from "common/constants/events";
import {CommonRoute, FleetRoute} from "common/constants/routes";
import {Fleet, HoldingFleet, isDraftFleet, isFleet} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {BaseModal, Button, ListItemLayout, TextField, Typography} from "@bolteu/kalep-react";

import {useCompanySelector} from "../CompanySelectorWithApplication/hooks/useCompanySelector";
import {useSearchedCompanies} from "./hooks/useSearchedCompanies";

const MIN_NUM_OF_COMPANIES_FOR_SEARCH = 10;

interface MobileCompanySelectorProps {
    isOpen: boolean;
    onRequestClose: () => void;
}

const MobileCompanySelector = (
    {isOpen, onRequestClose}: MobileCompanySelectorProps,
    ref: ForwardedRef<HTMLDivElement>,
) => {
    const accountState = useContext(AccountContextProvider);
    const navigate = useNavigate();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const [search, setSearch] = useState("");

    const {profile} = accountState;
    const selectedCompany = accountState.selectedCompany?.company;

    const availableHoldingFleets = accountState.profile?.holdingFleets;
    const availableFleets = accountState.profile?.fleets;

    const filteredFleets = useSearchedCompanies(search, availableFleets) as Fleet[];
    const filteredHoldingFleets = useSearchedCompanies(search, availableHoldingFleets) as HoldingFleet[];

    const shouldShowSearch =
        (availableFleets ?? []).length + (availableHoldingFleets ?? []).length > MIN_NUM_OF_COMPANIES_FOR_SEARCH;

    const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setSearch(val);
    }, []);

    const onApplicationLinkClick = useCallback(() => {
        onRequestClose();
    }, [onRequestClose]);

    const {getDescription, getCompanySettingsShown, getStatusChip, renderCompanySelectionItems, getCompanyName} =
        useCompanySelector();
    const shouldShowCompanySettings = getCompanySettingsShown(selectedCompany);
    const description = getDescription(selectedCompany, false, false, onApplicationLinkClick);
    const selectedCompanyName = getCompanyName(selectedCompany);

    const renderStatusChip = useCallback(() => {
        if (selectedCompany && isFleet(selectedCompany)) {
            return getStatusChip(selectedCompany.status);
        }
        return null;
    }, [getStatusChip, selectedCompany]);

    const navigateToCompanySettings = useCallback(() => {
        navigate(FleetRoute.COMPANY_SETTINGS);
        onRequestClose();
    }, [navigate, onRequestClose]);

    const navigateToAddCompany = useCallback(() => {
        trackEvent(EventName.AddCompanyButtonClicked);
        navigate(CommonRoute.ADD_COMPANY);
    }, [navigate, trackEvent]);

    const shouldHideAddCompanyButton = profile?.fleets.length && profile?.fleets.every((fleet) => isDraftFleet(fleet));

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} placement="m-0">
            <div className="h-dvh w-dvh flex flex-col gap-2 overflow-hidden bg-neutral-300" ref={ref}>
                <div className="bg-layer-floor-0 rounded-b-md">
                    <div className="mt-2 mr-2 flex justify-end">
                        <CloseButton onClick={onRequestClose} />
                    </div>
                    <div className="mb-4 flex flex-col gap-4 px-6">
                        <div>
                            <Typography variant="label-primary" fontSize="text-3xl" fontWeight="semibold">
                                {selectedCompanyName}
                            </Typography>
                            <Typography variant="label-primary" fontSize="text-base" fontWeight="regular">
                                <div className="text-content-secondary">{description}</div>
                            </Typography>
                        </div>
                        <div>
                            {shouldShowCompanySettings && (
                                <Button size="md" variant="secondary" onClick={navigateToCompanySettings}>
                                    {i18n("company-selector.open_settings")}
                                </Button>
                            )}
                            {!shouldShowCompanySettings && renderStatusChip()}
                        </div>
                    </div>
                </div>
                <div className="bg-layer-floor-0 h-dvh flex flex-col overflow-scroll rounded-t-md px-2">
                    {profile && (
                        <>
                            <div>
                                <ListItemLayout
                                    variant="base"
                                    primary={
                                        <Typography fontSize="text-lg" fontWeight="semibold" variant="body-primary">
                                            {i18n("company-selector.select_company")}
                                        </Typography>
                                    }
                                />
                                {shouldShowSearch && (
                                    <div className="mx-2">
                                        <TextField
                                            value={search}
                                            onChange={handleSearchChange}
                                            placeholder={i18n("company-selector.search_placeholder")}
                                            type="search"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="flex-1 overflow-auto" id="body-scroll-lock-ignore">
                                {renderCompanySelectionItems(
                                    accountState.selectedCompany,
                                    filteredFleets,
                                    filteredHoldingFleets,
                                )}
                            </div>
                        </>
                    )}
                    {!shouldHideAddCompanyButton && (
                        <div className="px-4 py-6">
                            <Button variant="secondary" size="md" onClick={navigateToAddCompany}>
                                {i18n("company-selector.add_company")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </BaseModal>
    );
};

export default forwardRef<HTMLDivElement, MobileCompanySelectorProps>((props, ref) =>
    MobileCompanySelector(props, ref),
);
