import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ListingFieldsTranslations = VehicleMarketplaceService.ListingFieldsTranslations;

interface Props {
    listingDataTranslations: ListingFieldsTranslations;
}

const DriverRequirements = ({listingDataTranslations}: Props) => {
    const {i18n} = useI18n();

    const requirementsTitle = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.driver_requirements",
    );
    const additionalRequirementsTitle = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.additional_requirements",
    );

    const applicantTerms = Object.values(listingDataTranslations.applicant_terms?.values || []);
    const applicantRequirements = Object.values(listingDataTranslations.applicant_requirements?.values || []);

    return (
        <div className="flex flex-col gap-6">
            <div>
                <p className="bolt-font-heading-xs-accent pb-4">{requirementsTitle}</p>
                <ul className="list-disc pl-5">
                    {applicantTerms.map(({key, value}) => (
                        <li key={key}>
                            {key}: {value}
                        </li>
                    ))}
                </ul>
            </div>
            {applicantRequirements.length && (
                <div>
                    <p className="pb-4 font-semibold">{additionalRequirementsTitle}</p>
                    <ul className="list-disc pl-5">
                        {applicantRequirements.map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DriverRequirements;
