import {useCallback, useContext, useState} from "react";

import DriverFilter from "common/components/DriverFilter";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import {AllowedDatePeriod} from "common/components/periodDatePicker/CustomPeriodPicker";
import {useDrivers} from "common/hooks/useDrivers";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import {Button, Menu, MenuItem, MenuItemProps, SelectOption} from "@bolteu/kalep-react";

import {ScheduledRidesContextProvider} from "../../ScheduledRidesContextProvider";
import {DATE_RANGE_LIMIT_DAYS} from "../constants";
import {CreateDrawerType} from "../types";

interface Props {
    selectedPeriod: ApiPeriod;
    onCreateOrderClick: (type: CreateDrawerType) => () => void;
    onSearchChange: (driverIds: number[]) => void;
    onPeriodChange: (period: ApiPeriod) => void;
}

export const TableFiltersAndActions = ({selectedPeriod, onCreateOrderClick, onSearchChange, onPeriodChange}: Props) => {
    const {i18n} = useI18n();
    const config = useContext(ScheduledRidesContextProvider);
    const [selectedDriverIds, setSelectedDriverIds] = useState<number[]>([]);
    const {drivers, status: fetchStatus} = useDrivers(selectedPeriod);

    const handleDriverIdsChange = useCallback(
        (selectedDrivers: SelectOption[] | null) => {
            if (selectedDriverIds.length === 0 && !selectedDrivers) {
                // Clicking backspace in empty input field
                return;
            }
            const driverIds = selectedDrivers?.map((driver) => driver.value as number) ?? [];
            onSearchChange(driverIds);
            setSelectedDriverIds(driverIds ?? []);
        },
        [onSearchChange, selectedDriverIds],
    );

    const renderMenuItemLabel = useCallback((props: MenuItemProps) => {
        return (
            <div className="max-w-full">
                <div className="bolt-font-body-m-regular font-semibold">{props.label}</div>
                <div className="text-secondary bolt-font-body-s-regular">{props.description}</div>
            </div>
        );
    }, []);

    const schedulingConfig = config?.getSchedulingLimits();

    return (
        <div className="flex flex-col justify-between gap-4 lg:flex-row">
            <DriverFilter
                driverIds={selectedDriverIds}
                onChange={handleDriverIdsChange}
                drivers={drivers}
                placeholder={i18n("auth.app.fleet.shifts.activity_log.filter_by_drivers")}
                loading={fetchStatus === FetchStatus.Loading}
                includeAllOption={false}
                multiple
            />
            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
                <PeriodDatePicker
                    period={selectedPeriod}
                    selectPeriodOptions={[
                        SelectPeriodOption.ThisWeek,
                        SelectPeriodOption.NextWeek,
                        SelectPeriodOption.ThisMonth,
                        SelectPeriodOption.NextMonth,
                    ]}
                    onPeriodChange={onPeriodChange}
                    dateRangeLimitDays={DATE_RANGE_LIMIT_DAYS}
                    allowedDateIntervalDays={schedulingConfig.maximum_date_in_future_days}
                    allowedDatePeriod={AllowedDatePeriod.Future}
                />
                <Menu
                    menuButton={<Button>{i18n("auth.app.orders.scheduled_rides.create-order")}</Button>}
                    direction="top"
                    boundingBoxPadding="0 16 0 0"
                >
                    {config.isInstantRidesEnabled && (
                        <MenuItem
                            value="instant"
                            onClick={onCreateOrderClick(CreateDrawerType.INSTANT)}
                            label={i18n("auth.app.orders.scheduled_rides.instant-order")}
                            description={i18n("auth.app.orders.scheduled_rides.instant-order-description")}
                            wrapText
                            renderLabel={renderMenuItemLabel}
                        />
                    )}
                    <MenuItem
                        value="schedule"
                        onClick={onCreateOrderClick(CreateDrawerType.SCHEDULE)}
                        label={i18n("auth.app.orders.scheduled_rides.scheduled-order")}
                        description={i18n("auth.app.orders.scheduled_rides.schedule-order-description")}
                        wrapText
                        renderLabel={renderMenuItemLabel}
                    />
                </Menu>
            </div>
        </div>
    );
};
