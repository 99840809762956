import {IntlShape} from "react-intl/src/types";

import {Dash} from "common/constants";
import {format, parse} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {apiTimestampToDate} from "./apiTimestampToDate";

const YEAR_MONTH_FORMAT = "yyyyMM";

export function getDateFormat(intl: IntlShape): string {
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];

    // There's a bug in date-fns which returns dd.mm.y for DE locale instead of dd.MM.yyyy
    // PR is submitted, can remove this when it will be merged: https://github.com/date-fns/date-fns/pull/3278
    if (intl.locale === "de-de") {
        return "dd.MM.yyyy";
    }

    const formattedDate = dateFnsLocale?.formatLong?.date({width: "short"}) as string | undefined;
    return formattedDate ?? "DD/MM/YYYY";
}

export function getDatetimeString(intl: IntlShape, timestamp: number): string {
    const date = apiTimestampToDate(timestamp);
    return `${intl.formatDate(date, {
        month: "long",
        day: "numeric",
    })}, ${intl.formatTime(date)}`;
}

interface FormatNumberOptions {
    minFractionDigits?: number;
    maxFractionDigits?: number;
    emptyCellDash?: Dash;
}

export function formatNumber(intl: IntlShape, value?: number | null, options?: FormatNumberOptions): string {
    if (!value) {
        return options?.emptyCellDash ?? Dash.EM;
    }
    const maxFractionDigits = options?.maxFractionDigits ?? 2;
    const minFractionDigits = options?.minFractionDigits ?? 1;
    return intl.formatNumber(value, {
        maximumFractionDigits: maxFractionDigits,
        minimumFractionDigits: minFractionDigits,
    });
}

export function convertToNegative(value: number): number {
    return -value;
}

export function getIsoWeek(date: Date): string {
    return format(date, "RRRR'W'II");
}

export function getYearMonth(date: Date): string {
    return format(date, "yyyy-MM");
}

export function getIsoDate(date: Date): string {
    return format(date, "yyyy-MM-dd");
}

export function getYearMonthDate(date: Date) {
    return format(date, YEAR_MONTH_FORMAT);
}

export function parseYearMonthDate(dateStr: string, refDate = new Date()) {
    return parse(dateStr, YEAR_MONTH_FORMAT, refDate);
}

export function getDayMonthYearDate(date: Date) {
    return format(date, "dd.MM.yyyy");
}

export function getTime(date: Date) {
    return format(date, "HH:mm");
}

export function getDayMonthTimeDate(unixTimestamp: number, intl: IntlShape) {
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
    return format(unixTimestamp * 1000, "dd MMM, HH:mm", {locale: dateFnsLocale});
}
