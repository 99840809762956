import {useCallback, useEffect, useState} from "react";

import ApiDrivenTable from "common/components/table/ApiDrivenTable";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {debounce} from "lodash-es";
import TableSearchField from "@fleet/common/components/table/TableSearchField";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";
import {History as HistoryIcon} from "@bolteu/kalep-react-icons";

interface ListingApplicationsTabProps {
    listingId: string;
}

const getVehicleListingApplications = (api: Api, body: VehicleMarketplaceService.SearchCompanyApplications) => {
    return api.vehicleMarketplace.vehicleApplicationSearch(body);
};

export default function ListingApplicationsTab({listingId}: ListingApplicationsTabProps) {
    const {i18n} = useI18n();

    const {fetch, data} = useFetch(getVehicleListingApplications);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (fetch) {
            fetch({
                listing_id: parseInt(listingId, 10),
                search_query: searchQuery,
            });
        }
    }, [fetch, listingId, searchQuery]);

    const onSearchChange = useCallback((search: string) => {
        debounce(() => setSearchQuery(search), 500);
    }, []);

    return (
        <div className="mt-4 flex w-full flex-col gap-4">
            <TableSearchField
                onChange={onSearchChange}
                placeholder={i18n("auth.app.fleet.vehicle_marketplace.listings.view.search_by_name")}
            />

            <div className="w-full">
                {data && (
                    <ApiDrivenTable
                        numberOfRecords={data.total_rows}
                        columns={data.columns}
                        tableId="listing-applications"
                    >
                        <EmptyState />
                    </ApiDrivenTable>
                )}
            </div>
        </div>
    );
}

function EmptyState() {
    const {i18n} = useI18n();

    return (
        <div className="flex w-full flex-col items-center justify-center gap-8 py-10">
            <HistoryIcon className="h-24 w-24" color="gray" />
            <div className="flex flex-col items-center justify-center gap-1">
                <Typography variant="body-l-accent">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.view.no_vehicles_listed")}
                </Typography>
                <button type="button">
                    <div className="text-action-primary">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.view.add_vehicle_listing")}
                    </div>
                </button>
            </div>
        </div>
    );
}
