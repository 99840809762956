import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {CellProps} from "../types";
import {DateCell} from "./DateCell";
import {DurationCell} from "./DurationCell";
import {EnumCell} from "./EnumCell";
import {NumberCell} from "./NumberCell";
import {StatusCell} from "./StatusCell";
import {StringCell} from "./StringCell";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ListColumnType = FleetOwnerPortalService.ListColumnType;
import {BooleanCellContent} from "./BooleanCell";

interface CellRendererProps extends CellProps {
    isSubCell?: boolean;
    isMobileView?: boolean;
}

interface SubContentRendererProps {
    valueAtIdx: number;
    subColumns: ApiColumn[];
    isReferenceCell?: boolean;
}

interface MobileSubContentRendererProps {
    subColumns: ApiColumn[];
    valueAtIdx: number;
}

export const CellRenderer = (cellProps: CellRendererProps) => {
    switch (cellProps.column.type) {
        case ListColumnType.STRING:
            return <StringCell isSubCell={cellProps.isSubCell} value={cellProps.column.cells[cellProps.valueAtIdx]} />;
        case ListColumnType.NUMBER:
            return (
                <NumberCell
                    unit={cellProps.column.unit}
                    isSubCell={cellProps.isSubCell}
                    alignHorizontal={cellProps.alignHorizontal}
                    numberType={cellProps.column.number_type}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.DURATION:
            return (
                <DurationCell
                    isSubCell={cellProps.isSubCell}
                    alignHorizontal={cellProps.alignHorizontal}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.DATE:
            return (
                <DateCell
                    isSubCell={cellProps.isSubCell}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                    type={cellProps.column.date_type}
                />
            );
        case ListColumnType.BOOLEAN:
            return (
                <BooleanCellContent
                    isSubCell={cellProps.isSubCell}
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                />
            );
        case ListColumnType.STATUS:
            return (
                <StatusCell
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                    valueMap={cellProps.column.value_map}
                />
            );
        case ListColumnType.ENUM:
            return (
                <EnumCell
                    value={cellProps.column.cells[cellProps.valueAtIdx]}
                    valueMap={cellProps.column.value_map}
                    isMobile={cellProps.isMobileView}
                />
            );
        default:
            return <></>;
    }
};

export const SubContentRenderer = ({subColumns, valueAtIdx, isReferenceCell}: SubContentRendererProps) => {
    if (!subColumns || subColumns.length === 0) {
        return null;
    }

    const subContent = subColumns.map((column) => {
        return (
            <div key={column.key}>
                {column.bind_title && <span className="mr-1">{`${column.bind_title}:`}</span>}
                <span className="inline-flex items-baseline">
                    <CellRenderer column={column} valueAtIdx={valueAtIdx} isSubCell />
                </span>
            </div>
        );
    });

    return (
        <div className={isReferenceCell ? "" : "mt-2"}>
            <Typography variant="body-tabular-s-regular" color="secondary" fontSize="text-xs">
                {subContent}
            </Typography>
        </div>
    );
};

export const MobileSubContentRenderer = ({subColumns, valueAtIdx}: MobileSubContentRendererProps) => {
    const subContent = subColumns?.map((column) => {
        return (
            <div key={column.key} className="ml-3 mt-1 flex items-baseline gap-y-1 gap-x-2">
                <span>{column.bind_title && `${column.bind_title}:`}</span>
                <div className="border-separator min-w-[8px] flex-1 grow border-0 border-b" />
                <span className="inline-flex">
                    <CellRenderer column={column} valueAtIdx={valueAtIdx} isSubCell />
                </span>
            </div>
        );
    });

    return (
        <div className="mt-2">
            <Typography variant="body-tabular-s-regular" color="secondary">
                {subContent && subContent.length > 0 && subContent}
            </Typography>
        </div>
    );
};
