import {UnreachableCode} from "utils/UnreachableCode";

import carImgUrl from "../../assets/vehicles/car.png";
import bikeImgUrl from "../../assets/vehicles/car_bike.png";
import hatchbackImgUrl from "../../assets/vehicles/car_hatchback.png";
import minivanImgUrl from "../../assets/vehicles/car_minivan.png";
import rickshawImgUrl from "../../assets/vehicles/car_rickshaw.png";
import vanImgUrl from "../../assets/vehicles/car_van.png";

const BodyType = {
    SEDAN: "sedan",
    VAN: "van",
    MINIVAN: "minivan",
    WAGON: "wagon",
    SUV: "suv",
    HATCHBACK: "hatchback",
    RICKSHAW: "rickshaw",
    MOTORBIKE: "motorbike",
} as const;
type BodyTypeVariants = (typeof BodyType)[keyof typeof BodyType];

export function getVehiclePictureByType(bodyType: BodyTypeVariants) {
    switch (bodyType) {
        case BodyType.HATCHBACK:
            return hatchbackImgUrl;
        case BodyType.MOTORBIKE:
            return bikeImgUrl;
        case BodyType.RICKSHAW:
            return rickshawImgUrl;
        case BodyType.MINIVAN:
            return minivanImgUrl;
        case BodyType.VAN:
        case BodyType.WAGON:
            return vanImgUrl;
        case BodyType.SEDAN:
        case BodyType.SUV:
            return carImgUrl;
        default:
            return UnreachableCode.never(bodyType, carImgUrl);
    }
}
