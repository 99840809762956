import {useCallback, useContext, useEffect, useMemo} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api, ApiContextProvider} from "common/services/apiProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";
import {debounce} from "lodash-es";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ScheduledRidesContextProvider} from "../../ScheduledRidesContextProvider";
import {AddressType} from "../types";

import Suggestion = FleetPortalOrderService.Suggestion;

const getPickupSuggestionsFunction = (api: Api, body: FleetPortalOrderService.GetAddressSuggestionsRequest) =>
    api.fleetPortalOrder.getPickupSuggestions(body);
const getDropoffSuggestionsFunction = (api: Api, body: FleetPortalOrderService.GetAddressSuggestionsRequest) =>
    api.fleetPortalOrder.getDropoffSuggestions(body);

export const useAddressSuggestions = (addressType: AddressType, geoLocation: GeolocationPosition | null) => {
    const {isDropoffAddressRequired, setIsDropoffAddressRequired} = useContext(ScheduledRidesContextProvider);
    const api = useContext(ApiContextProvider);
    const {bugsnag} = useContext(ThirdPartyContextProvider);
    const {fetch: getPickupSuggestionsFetch, ...pickupFetchData} = useFetch(getPickupSuggestionsFunction);
    const {fetch: getDropoffSuggestionsFetch, ...dropoffFetchData} = useFetch(getDropoffSuggestionsFunction);

    const getPickupSuggestions = useCallback(
        (search: string) => {
            if (getPickupSuggestionsFetch) {
                getPickupSuggestionsFetch({
                    search_string: search,
                    gps_lat: geoLocation?.coords.latitude,
                    gps_lng: geoLocation?.coords.longitude,
                });
            }
        },
        [geoLocation?.coords, getPickupSuggestionsFetch],
    );
    const getDropoffSuggestions = useCallback(
        (search: string) => {
            if (getDropoffSuggestionsFetch) {
                getDropoffSuggestionsFetch({
                    search_string: search,
                    gps_lat: geoLocation?.coords.latitude,
                    gps_lng: geoLocation?.coords.longitude,
                });
            }
        },
        [geoLocation?.coords, getDropoffSuggestionsFetch],
    );

    const recordPickupAddressEntered = useCallback(
        async (search: string, suggestions: Suggestion[], selectedSuggestion: Suggestion) => {
            if (!api) {
                return;
            }

            const selectedIndex = suggestions.findIndex(
                (suggestion) => suggestion.full_address === selectedSuggestion.full_address,
            );

            try {
                await api.fleetPortalOrder.recordPickupAddressEntered({
                    gps_lat: geoLocation?.coords.latitude,
                    gps_lng: geoLocation?.coords.longitude,
                    search_string: search,
                    clicked_suggestion_list_index: selectedIndex,
                    clicked_suggestion_coords:
                        selectedSuggestion.lat && selectedSuggestion.lng
                            ? {lat: selectedSuggestion.lat, lng: selectedSuggestion.lng}
                            : undefined,
                    clicked_suggestion_place_id: selectedSuggestion.place_id,
                    suggestions,
                });
            } catch (e) {
                bugsnag.bugsnagNotify(new Error("Failed to record selected pickup suggestion", {cause: e}));
            }
        },
        [api, bugsnag, geoLocation?.coords],
    );

    const recordDropoffAddressEntered = useCallback(
        async (search: string, suggestions: Suggestion[], selectedSuggestion: Suggestion) => {
            if (!api) {
                return;
            }

            const selectedIndex = suggestions.findIndex(
                (suggestion) => suggestion.full_address === selectedSuggestion.full_address,
            );

            try {
                await api.fleetPortalOrder.recordDropoffAddressEntered({
                    gps_lat: geoLocation?.coords.latitude,
                    gps_lng: geoLocation?.coords.longitude,
                    search_string: search,
                    clicked_suggestion_list_index: selectedIndex,
                    clicked_suggestion_coords:
                        selectedSuggestion.lat && selectedSuggestion.lng
                            ? {lat: selectedSuggestion.lat, lng: selectedSuggestion.lng}
                            : undefined,
                    clicked_suggestion_place_id: selectedSuggestion.place_id,
                    suggestions,
                });
            } catch (e) {
                bugsnag.bugsnagNotify(new Error("Failed to record selected dropoff suggestion", {cause: e}));
            }
        },
        [api, bugsnag, geoLocation?.coords],
    );

    useEffect(() => {
        if (
            pickupFetchData.status === FetchStatus.Success &&
            isDropoffAddressRequired !== !pickupFetchData.data.can_skip_destination
        ) {
            setIsDropoffAddressRequired(!pickupFetchData.data.can_skip_destination);
        } else if (pickupFetchData.status === FetchStatus.Error) {
            bugsnag.bugsnagNotify(new Error(`Failed to get pickup suggestions, ${pickupFetchData.error.message}`));
        }
    }, [bugsnag, isDropoffAddressRequired, pickupFetchData, setIsDropoffAddressRequired]);

    useEffect(() => {
        if (
            dropoffFetchData.status === FetchStatus.Success &&
            isDropoffAddressRequired !== !dropoffFetchData.data.can_skip_destination
        ) {
            setIsDropoffAddressRequired(!dropoffFetchData.data.can_skip_destination);
        } else if (dropoffFetchData.status === FetchStatus.Error) {
            bugsnag.bugsnagNotify(new Error(`Failed to get dropoff suggestions, ${dropoffFetchData.error.message}`));
        }
    }, [isDropoffAddressRequired, dropoffFetchData, setIsDropoffAddressRequired, bugsnag]);

    const getPickupSuggestionsDebounce = useMemo(
        () => debounce((newSearch: string) => getPickupSuggestions(newSearch), 500),
        [getPickupSuggestions],
    );
    const getDropoffSuggestionsDebounce = useMemo(
        () => debounce((newSearch: string) => getDropoffSuggestions(newSearch), 500),
        [getDropoffSuggestions],
    );

    if (addressType === AddressType.Pickup) {
        return {
            getSuggestions: getPickupSuggestionsDebounce,
            fetchData: pickupFetchData,
            recordSelectedSuggestion: recordPickupAddressEntered,
        };
    }

    return {
        getSuggestions: getDropoffSuggestionsDebounce,
        fetchData: dropoffFetchData,
        recordSelectedSuggestion: recordDropoffAddressEntered,
    };
};
