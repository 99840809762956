import {FC, useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod, filePeriod} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {Button, SelectOption} from "@bolteu/kalep-react";

import {useDriverOrderHistoryDownloadApi} from "../hooks/useDriverOrderHistoryDownloadApi";
import {useOrderHistoryDownloadApi} from "../hooks/useOrderHistoryDownloadApi";
import {isDriverSelected} from "../util";

interface OrderHistoryDownloadProps {
    driver: SelectOption | null;
    period: ApiPeriod;
    isError: boolean;
}

const OrderHistoryDownload: FC<OrderHistoryDownloadProps> = ({driver, period, isError}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const account = useContext(AccountContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const [base64File, setBase64File] = useState<string | null>(null);

    const driverId = driver?.value as number;
    const driverName = driver?.title as string;

    const driverOrderdownloadApi = useDriverOrderHistoryDownloadApi(driverId || 0, period);
    const orderDownloadApi = useOrderHistoryDownloadApi(period);

    const {
        data: downloadedReport,
        fetch: downloadReport,
        status: downloadedReportStatus,
    } = useFetch(isDriverSelected(driver) ? driverOrderdownloadApi : orderDownloadApi);

    useEffect(() => {
        if (downloadedReportStatus === FetchStatus.Success) {
            if (!downloadedReport.file) {
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("auth.app.orders.history.no-data-error"),
                });
            } else {
                setBase64File(downloadedReport.file);
            }
        }
    }, [downloadedReport, downloadedReportStatus, i18n, setNotification]);

    useEffect(() => {
        const companyName = account.selectedCompany?.company.name;
        if (base64File && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);

            const fileName = !isDriverSelected(driver)
                ? `${i18n("auth.app.orders.history.title")}-${periodStr}-${cleanCompanyName}`
                : `Bolt-${i18n("auth.app.orders.history.title")}-${driverName}`;
            downloadBase64File(base64File, MimeTypes.CSV, fileName);
            setBase64File(null);
        }
    }, [
        account.selectedCompany?.company.name,
        base64File,
        downloadedReport,
        downloadedReportStatus,
        intl,
        i18n,
        period,
        driverName,
        driver,
    ]);

    const downloadCsv = useCallback(() => {
        if (downloadReport) {
            downloadReport({});
        }
    }, [downloadReport]);

    return (
        <div className="flex justify-end">
            <Button
                variant="secondary"
                onClick={downloadCsv}
                loading={downloadedReportStatus === FetchStatus.Loading}
                disabled={isError}
            >
                {i18n("common.download")}
            </Button>
        </div>
    );
};

export default OrderHistoryDownload;
