import {FC, useContext, useEffect, useState} from "react";

import DeactivatedEntityIsland from "common/components/deactivatedEntityIsland/DeactivatedEntityIsland";
import {DocumentsAlert} from "common/components/document/DocumentsAlert";
import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";
import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {isCompanyCountryUsingCash} from "common/util/isCompanyCountryUsingCash";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useDocumentFeature} from "../../hooks/useDocumentFeature";
import BlockedDriverAlert from "./BlockedDriverAlert";
import DriverDetails from "./DriverDetails";
import DriverRewardsLevelDetails from "./DriverRewardsLevelDetails";
import DriverTiles from "./DriverTiles";
import PageHeader from "./PageHeader";
import Permissions from "./Permissions";
import ToggleDriverDeactivationButton from "./ToggleDriverDeactivationButton";
import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface DriverProfileBodyProps {
    driver: FleetOwnerPortalService.GetDriverResponse;
    toggleDriverDeactivationStatus: (newPortalStatus: EntityPortalStatus) => void;
}

const getDriverBlocksFunction = (api: Api, query: FleetOwnerPortalService.GetDriverBlocksRequest) =>
    api.fleetOwnerPortal.driverGetBlocksHistory(query);

const DriverProfileBody: FC<DriverProfileBodyProps> = ({driver, toggleDriverDeactivationStatus}) => {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const features = useContext(FeaturesContextProvider);

    const {data: blocks, fetch} = useFetch(getDriverBlocksFunction);

    const [hasCashPayments, setHasCashPayments] = useState(false);

    useEffect(() => {
        if (!fetch || !features?.driver_blocks) {
            return;
        }
        fetch({driver_id: driver.id});
    }, [fetch, driver.id, features?.driver_blocks]);

    const isDriverDeactivated = driver.portal_status === EntityPortalStatus.Deactivated;
    const isCompanyUsingCash = fleet && isCompanyCountryUsingCash(fleet.country);

    const {isFeatureEnabled: isDriverDocumentsEnabled} = useDocumentFeature();
    const {documentsListProps, documentsAlertsProps} = useDocumentsList({
        areDocumentsEnabled: isDriverDocumentsEnabled,
        owner: {id: driver.id, type: DocumentOwnerType.Driver},
        isUploadAllowed: !isDriverDeactivated,
    });

    useEffect(() => {
        setHasCashPayments(driver.has_cash_payment === 1);
    }, [driver.has_cash_payment]);

    const isCashPaymentsToggleButtonEnabled = driver.show_cash_payment_toggle_button;

    const shouldShowCashPaymentsChip = Boolean(isCompanyUsingCash && !hasCashPayments);
    const shouldShowDocumentsList = isDriverDocumentsEnabled && documentsListProps;
    const shouldShowPermissions = isCompanyUsingCash && !isDriverDeactivated;
    const shouldShowDocumentsAlert = isDriverDocumentsEnabled && documentsAlertsProps;
    const shouldShowBlocksAlert = features?.driver_blocks;

    let InfoIsland = null;
    if (driver.portal_status === EntityPortalStatus.Blocked) {
        if (shouldShowDocumentsAlert || shouldShowBlocksAlert) {
            InfoIsland = (
                <div className="flex flex-col gap-4">
                    {shouldShowDocumentsAlert && <DocumentsAlert {...documentsAlertsProps} />}
                    {shouldShowBlocksAlert && <BlockedDriverAlert blocks={blocks?.list || []} />}
                </div>
            );
        }
    } else if (driver.portal_status === EntityPortalStatus.Deactivated) {
        InfoIsland = (
            <DeactivatedEntityIsland
                title_key="auth.app.fleet.driver-profile.deactivation_island.title"
                deactivated_by_fleet_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_fleet_text"
                deactivated_by_bolt_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_bolt_text"
                deactivated_by_bolt_date_unknown_text_key="auth.app.fleet.driver-profile.deactivation_island.deactivated_by_bolt_date_unknown_text"
                deactivationDetails={driver.deactivation_details}
            />
        );
    }

    return (
        <div className="mt-6 flex flex-col gap-8">
            <div className="flex items-start justify-between">
                <PageHeader shouldShowCashPaymentsChip={shouldShowCashPaymentsChip} driver={driver} />
                {driver.show_block_button && (
                    <ToggleDriverDeactivationButton
                        key={driver.portal_status}
                        driverId={driver.id}
                        driverPortalStatus={driver.portal_status}
                        isDeactivatedByFleetOwner={Boolean(driver.deactivation_details?.is_deactivated_by_fleet_owner)}
                        onSuccessAction={toggleDriverDeactivationStatus}
                    />
                )}
            </div>
            <div className="grid grid-cols-1 gap-y-4 md:grid-cols-12">
                {InfoIsland && <div className="col-span-7">{InfoIsland}</div>}
                <div className="col-span-7 col-start-auto mb-4">
                    <DriverTiles driver={driver} blocks={blocks?.list || []} />
                    <DriverDetails
                        driverData={driver}
                        carRentalPaymentsEnabled={fleet?.targeting.car_rental_payments_enabled}
                    />
                </div>
                <div className="col-span-4 col-end-[-1] flex flex-col gap-4">
                    {driver.driver_rewards_level_details && (
                        <DriverRewardsLevelDetails data={driver.driver_rewards_level_details} />
                    )}
                    {shouldShowPermissions && (
                        <Permissions
                            driverId={driver.id}
                            setHasCashPayments={setHasCashPayments}
                            isCashPaymentsToggleButtonEnabled={isCashPaymentsToggleButtonEnabled}
                            cashPaymentsEnabled={hasCashPayments}
                        />
                    )}
                    {shouldShowDocumentsList && <DocumentsList {...documentsListProps} />}
                </div>
            </div>
        </div>
    );
};

export default DriverProfileBody;
