import {useCallback, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {useTabs} from "common/components/tab/useTabs";
import {useReactRouterLinkForBreadcrumb} from "common/hooks/useBreadcrumbRenderItem";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb} from "@bolteu/kalep-react";

import ListingActions from "./components/ListingActions";
import PageHeader from "./components/PageHeader";
import ViewListingInVMLink from "./components/ViewListingInVMLink";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import {useTabOptions} from "./hooks/useTabOptions";
import ListingApplicationsTab from "./ListingApplicationsTab";
import ListingViewTab from "./ListingViewTab";

export enum ListingTab {
    View = "view",
    RentalRequests = "rental_requests",
}

const getListingDetailsFetchFunction = (api: Api, request: VehicleMarketplaceService.GetListingRequest) =>
    api.vehicleMarketplace.vehicleListingGet(request);

const ViewListingPage = () => {
    const {id: listingId} = useParams();
    const {i18n} = useI18n();
    const tabs = useTabOptions();
    const viewport = useTailwindViewport();

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {data, status, fetch} = useFetch(getListingDetailsFetchFunction);

    const {activeTab, TabSelector} = useTabs(tabs, ListingTab.View);
    const breadcrumbs = useBreadcrumbs(fleet?.id, data?.data.vehicle_model_name, data?.data.vehicle_years || undefined);
    const isMobileView = viewport === "sm";

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.vehicle_marketplace.listings.view.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const fetchListingData = useCallback(() => {
        if (fetch) {
            fetch({listing_id: Number(listingId)});
        }
    }, [fetch, listingId]);

    useEffect(() => {
        fetchListingData();
    }, [fetchListingData]);

    if (status === FetchStatus.Init || status === FetchStatus.Loading) {
        return <LoadingSpinner show parentClassName="pt-12 w-full" />;
    }

    if (status === FetchStatus.Error || Number.isNaN(Number(listingId))) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    const {data: listingData, translations: listingDataTranslations} = data;

    return (
        <Page>
            <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
            <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <PageHeader listingData={listingData} />
                {isMobileView && <ViewListingInVMLink listingId={Number(listingId)} />}
                {!isMobileView && (
                    <ListingActions
                        listingId={Number(listingId)}
                        listingData={listingData}
                        refreshListingData={fetchListingData}
                    />
                )}
            </div>
            <TabSelector />
            {activeTab === ListingTab.View && (
                <ListingViewTab
                    listingId={Number(listingId)}
                    listingData={listingData}
                    listingDataTranslations={listingDataTranslations}
                    refreshListingData={fetchListingData}
                />
            )}
            {activeTab === ListingTab.RentalRequests && listingId && <ListingApplicationsTab listingId={listingId} />}
        </Page>
    );
};

export default ViewListingPage;
