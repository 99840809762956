import {FC} from "react";

import Bronze from "assets/icons/bronze.svg?react";
import BronzeInactive from "assets/icons/bronze-inactive.svg?react";
import Gold from "assets/icons/gold.svg?react";
import GoldInactive from "assets/icons/gold-inactive.svg?react";
import Platinum from "assets/icons/platinum.svg?react";
import PlatinumInactive from "assets/icons/platinum-inactive.svg?react";
import Silver from "assets/icons/silver.svg?react";
import SilverInactive from "assets/icons/silver-inactive.svg?react";
import Header, {HeaderSize} from "common/components/header/Header";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";
import {Alert as AlertIcon, Check} from "@bolteu/kalep-react-icons";
import GetLoyaltyLevelDetailsForFleetOwnerResponse = FleetOwnerPortalService.GetLoyaltyLevelDetailsForFleetOwnerResponse;
import TierStatus = FleetOwnerPortalService.TierStatus;

interface RewardsLevelProps {
    data: GetLoyaltyLevelDetailsForFleetOwnerResponse;
}

type Tier = 0 | 1 | 2 | 3;

const LEVEL_ICONS = new Map<Tier, Record<TierStatus, JSX.Element>>([
    [0, {ACTIVE: <Bronze />, INACTIVE: <BronzeInactive />}],
    [1, {ACTIVE: <Silver />, INACTIVE: <SilverInactive />}],
    [2, {ACTIVE: <Gold />, INACTIVE: <GoldInactive />}],
    [3, {ACTIVE: <Platinum />, INACTIVE: <PlatinumInactive />}],
]);

const LEVEL_COLORS = new Map<Tier, {background: string; progress_bar: string}>([
    [0, {background: "var(--color-bronze-background)", progress_bar: "var(--color-bronze-progress-bar)"}],
    [1, {background: "var(--color-silver-background)", progress_bar: "var(--color-silver-progress-bar)"}],
    [2, {background: "var(--color-gold-background)", progress_bar: "var(--color-gold-progress-bar)"}],
    [3, {background: "var(--color-platinum-background)", progress_bar: "var(--color-platinum-progress-bar)"}],
]);

const FALLBACK_COLORS = {
    background: "white",
    progress_bar: "white",
};

const DriverRewardsLevelDetails: FC<RewardsLevelProps> = ({data}) => {
    const {i18n} = useI18n();
    const {driver_score: driverScore, acceptance_rate: acceptanceRate, current_level: currentLevel, content} = data;

    const isRewardsActiveForDriver = currentLevel.status === FleetOwnerPortalService.TierStatus.ACTIVE;
    const progressPercentage = currentLevel.points_goal
        ? Math.min(Math.floor((currentLevel.points / currentLevel.points_goal) * 100), 100)
        : 100;

    const getLevelIcon = (): JSX.Element => {
        return LEVEL_ICONS.get(currentLevel.tier as Tier)?.[currentLevel.status] || <></>;
    };

    const getColor = (type: "background" | "progress_bar") => {
        const colors = LEVEL_COLORS.get(currentLevel.tier as Tier);
        return colors?.[type] ?? FALLBACK_COLORS[type];
    };

    const renderScoreProgress = (current: number, required: number, unit = "") => {
        const isWarning = current >= required ? "" : "text-danger-primary";
        return (
            <span>
                <span className={`font-semibold ${isWarning}`}>
                    {current}
                    {unit}
                </span>
                <span className="text-secondary">
                    /{required}
                    {unit}
                </span>
                {current >= required ? (
                    <Check size="sm" className="text-action-secondary ml-2" />
                ) : (
                    <AlertIcon size="sm" className="text-danger-primary ml-2" />
                )}
            </span>
        );
    };

    const goalDescription = isRewardsActiveForDriver
        ? content.rewards_section.points_goal_description
        : content.rewards_section.reactivate_description;

    return (
        <Island>
            <Header size={HeaderSize.ExtraSmall} text={content.rewards_section.title} className="pb-4" />
            <div className="flex flex-col justify-between">
                <div className="rounded-lg p-5" style={{backgroundColor: getColor("background")}}>
                    <div className="mb-5 flex items-center">
                        <span className="mr-2">{getLevelIcon()}</span>
                        <div className="flex flex-col">
                            <p className="text-lg font-semibold">{currentLevel.level_name}</p>
                            {!isRewardsActiveForDriver && (
                                <span className="text-secondary text-sm">{i18n("common.inactive")}</span>
                            )}
                        </div>
                    </div>
                    <div className="mb-1 flex items-center justify-between">
                        <p>{content.rewards_section.points_label}</p>
                        <p className="text-xl">
                            <span className="font-semibold">{currentLevel.points}</span>
                            {currentLevel.points_goal && (
                                <span className="text-secondary">/{currentLevel.points_goal}</span>
                            )}
                        </p>
                    </div>

                    <div className="relative mb-3 flex h-2 w-full items-center">
                        <div className="h-full w-full rounded-full bg-white" />
                        <div
                            className="absolute h-full rounded-full"
                            style={{
                                width: `${progressPercentage}%`,
                                backgroundColor: getColor("progress_bar"),
                            }}
                        />
                    </div>
                    <p className={`text-xs ${isRewardsActiveForDriver ? "text-secondary" : "text-danger-primary"}`}>
                        {goalDescription}
                    </p>
                </div>

                <div className="mt-4 flex flex-col">
                    <p className="text-secondary text-sm">{content.performance_section.driver_score_label}</p>
                    <p>{renderScoreProgress(driverScore.current, driverScore.required, driverScore.unit)}</p>
                </div>

                <div className="border-separator my-3 h-0 border-b-[1px]" />

                <div className="flex flex-col">
                    <p className="text-secondary text-sm">{content.performance_section.acceptance_rate_label}</p>
                    <p>{renderScoreProgress(acceptanceRate.current, acceptanceRate.required, acceptanceRate.unit)}</p>
                </div>
            </div>
        </Island>
    );
};

export default DriverRewardsLevelDetails;
